import React from 'react'
import { NavPages } from '../components/NavPages'
import { Footer } from '../components/Footer'

const Privacy = () => {

  return (
    <>
      <NavPages />
        <div className="container">
          <div className="row">
            <div className="col-lg-9 ma">
              <div className="s-100"></div>
              <h3 className='title c-dark'>Privacy policy</h3>
              <div className="s-40"></div>
              <h4 className='c-dark py-3'>1. General Provisions</h4>
              <p className='c-dark'>The Policy of Confidentiality (hereinafter "the Policy") of the charity organization "CF IT-SPROUT" was developed in accordance with the Law of Ukraine "On the Protection of Personal Data", and other legal acts that regulate relations in the fiduciary field.</p>

              <p className='c-dark'>The policy contains the standards that the Fund uses to work with the personal information of visitors to the Site (hereinafter "the User"), which the Fund can obtain through the Site located at the address: https://it-sprout.org.ua/ (hereinafter "the Site"), and also explains how you can contact the Fund in case of any questions or comments.</p>

              <p className='c-dark'>The User provides personal information to the Fund through the Site exclusively voluntarily, and it is confirmation that the User has familiarized themselves with the content of this Policy and Regulation on the protection of personal data and the procedure for their processing (hereinafter "the Regulation"), and gives their permission for the Fund to collect, process, use, and/or transfer the User's personal data.</p>

              <p className='c-dark'>Information and personal data are collected, processed and used in accordance with the requirements of the current legislation of Ukraine.</p>

              <h4 className='c-dark py-3'>2. Structure and Content of the User's Personal Data</h4>
              <p className='c-dark'>The Fund collects the User's personal data using the Site only if the User has voluntarily provided the relevant information and expressed consent to its collection, processing, storage, and/or use of their personal data.</p>

              <p className='c-dark'>We draw your attention to the fact that when the User visits the Site, certain data is automatically recorded on the servers used by the Foundation for the system administration of the Site, namely: the IP address with which the User accesses the Site; type of browser and operating system; date and time of visiting the Site; viewed pages; and in the case of going to the Site using a link from another site, the address of this site.</p>

              <p className='c-dark'>Third-party services, such as Google Analytics, can collect statistics about visits to the Site, domains of Internet service providers, countries of origin of visitors, as well as addresses of websites visited by the User before entering the Site and after the User leaves the Site, etc. Such third parties have their own privacy policies regarding the use of such information.</p>

              <p className='c-dark'>The Fund does not collect information about the User's means of payment, such as bank cards or similar information. In the case of making a charitable contribution through the Site, the payment data will not be provided to the Fund but to the payment operator/payment system and is governed by their Privacy Policy. These payment systems adhere to the standards set by PCI-DSS, which is managed by the PCI Security Standards Council. PCI-DSS requirements help ensure the secure processing of payment information.</p>

              <h4 className='c-dark py-3'>3. Use of Information</h4>
              <p className='c-dark'>Personal information and other information provided by the User are only used for the purpose for which they were provided to the Fund. Depending on the subject of personal data contacting the Fund using the Site, the Fund will use the information provided by the User for the purpose specified in the Regulations.</p>

              <h4 className='c-dark py-3'>4. Data Security</h4>
              <p className='c-dark'>The Fund undertakes to securely store information provided by the User, and to take all measures to protect information from loss, misuse, or unauthorized changes. The transmission of information over the Internet is not always completely safe, therefore, as soon as we receive information from you, we will do everything possible to protect your personal data.</p>

              <p className='c-dark'>The User confirms that they understand the risks described above, in particular that the information provided may be lost or obtained by third parties, and that they personally and independently take all the risks associated with this.</p>

              <p className='c-dark'>The User's personal data is stored for the entire time necessary to achieve the purpose of personal data processing, but no more than 1095 calendar days after the end of the reporting period in which such personal data was provided to the Fund by the User, unless a longer period is determined by the current legislation of Ukraine.</p>

              <p className='c-dark'>The Fund is not responsible for lost profit, unearned profit, loss of data, or any other losses of Site Users, if such occurred when using the Site.</p>

              <h4 className='c-dark py-3'>5. Dissemination of Information</h4>
              <p className='c-dark'>The Fund may transfer the information received from the User to third parties only to the extent objectively necessary to achieve the goal mentioned in the Regulations, or in order to protect the security or integrity of the Fund's Site, at the request of supervisory, law enforcement or judicial bodies, which are carried out in accordance with the procedure provided for by the current legislation of Ukraine, or in cases where it is necessary to provide such information in order to prevent physical harm or financial loss.</p>

              <p className='c-dark'>The site may contain links to other sites. Such links are provided solely for informational purposes and help the Fund to more fully disclose information or illustrate posted material.</p>

              <p className='c-dark'>If the User has any questions or concerns about this policy, they should contact the Fund at <a href="mailto:admin@it-sprout.org">admin@it-sprout.org</a>.</p>

              <p className='c-dark'>The Fund reserves the right to make changes to the Privacy Policy at any time and for any reason, without additional notification to the User of the relevant changes. In the event of changes to this Policy, notices about such changes will be posted on the Site and the date of entry into force of these changes will be indicated.</p>
              <div className="s-100"></div>
            </div>
          </div>
        </div>
      <Footer />
    </>
  )
}

export { Privacy }
