import React from 'react'
import { NavPages } from '../components/NavPages'
import { Footer } from '../components/Footer'

const Thanks = () => {

  return (
    <>
      <NavPages />
      <div className="container tc">
        <div className="row">
          <div className="col-lg-9 ma">
            <div className="s-100"></div>
            <h3 className='title c-dark'>Thank You for Your Generosity!</h3>
            <div className="s-40"></div>
            <p className='c-dark'>Your donation has been successfully processed, and we truly appreciate your support. Your contribution is making a real difference and helps us continue our work. We are so grateful for your kindness and generosity.</p>
            <p className='c-dark'>If you have any questions or need assistance, please don't hesitate to contact us. Stay connected with us for updates and stories about how your donation is making an impact!</p>
            <div className="s-100"></div>
            <div className="tc"><a href='/' className='button outline'>Return Home</a></div>
            <div className="s-100"></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export { Thanks }
