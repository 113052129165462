import React from 'react'
import logo from '../assets/logo-full.svg'

const NavPages = () => {
  return (
    <header className='bg-lite'>
      <div className="s-10"></div>
      <h1 id='logo' className='flex jcc ai'>
        <a href='/' aria-label='Logotype'>
          <img src={logo} width={200} height={48} alt='CF IT-SPROUT' />
        </a>
      </h1>
      <div className="s-10"></div>
    </header>
  )
}

export { NavPages }
