import React from 'react'
import { NavPages } from '../components/NavPages'
import { Footer } from '../components/Footer'

const NotFound = () => {

  return (
    <>
      <NavPages />
      <div className="container tc">
        <div className="row">
          <div className="col-lg-9 ma">
            <div className="s-100"></div>
            <h3 className='title c-dark'>Oops! The Page Has Gone Missing.</h3>
            <div className="s-40"></div>
            <p className='c-dark'>It looks like the page you're searching for has taken a wrong turn or moved to a new address. Don't worry, though! You can navigate back to our homepage or use the search function to find what you need. If you believe this is a mistake, please contact us, and we'll help guide you right away.</p>
            <div className="s-100"></div>
            <div className="tc"><a href='/' className='button outline'>Go Back Home</a></div>
            <div className="s-100"></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export { NotFound }
